<template>
  <div v-if="loaded">
    <div class="col-md-12 mb-3">
      <h1>
        Choose an entity type in {{ jurisdiction.state_province_region }}.
      </h1>
    </div>

    <ct-card-deck>
      <ct-card
        v-if="llcEntityType"
        :class="{ 'selected-payment-method': selectedPaymentMethod === 'llc' }"
        @click="entitySelectChanged(llcEntityType.id, 'llc')"
      >
        <input
          id="selectEntityTypeLLC"
          v-model="selectedEntityTypeId"
          type="radio"
          name="selectEntityType"
          class="entity-type-input"
          :value="llcEntityType.id"
        >
        <div class="card__left">
          <llc-svg class="card__image" alt="shop with awning" />
          LLC
        </div>
        <label class="card__text text-left ml-3" for="selectEntityTypeLLC">
          Limited liability companies are simple and affordable to own,
          making them the preferred structure for new and small business
          owners. Most&nbsp;people choose an LLC.
        </label>
      </ct-card>

      <ct-card
        v-if="corporationEntityType"
        :class="{ 'selected-payment-method': selectedPaymentMethod === 'corporation' }"
        @click="entitySelectChanged(corporationEntityType.id, 'corporation')"
      >
        <input
          id="selectEntityTypeCorp"
          v-model="selectedEntityTypeId"
          type="radio"
          name="selectEntityType"
          class="entity-type-input"
          :value="corporationEntityType.id"
        >
        <div class="card__left">
          <corporation-svg class="card__image" alt="buildings" />
          Corporation
        </div>
        <label class="card__text text-left ml-3" for="selectEntityTypeCorp">
          Corporations are more complex and expensive, but make excellent vehicles for formal
          ownership and management structures, and for raising large sums of capital.
        </label>
      </ct-card>
    </ct-card-deck>

    <div class="row other-entity-dropdown-row">
      <div class="col-md-6 mb-2 mb-md-0 vertical-align d-flex flex-column align-items-start">
        <h6 class="mt-0 mb-2">
          Looking for something else?
        </h6>
        <p class="text-left">
          <small>
            Our business guide supports additional entity types
            that you can add to your account instead.
          </small>
        </p>
      </div>

      <div class="col-md-6 mt-2">
        <b-form-select
          v-model="selectedEntityTypeDropdownId"
          class="w-100 other-entity-dropdown float-left"
          :options="nonLLCAndCorpEntityTypes"
          @change="entitySelectChanged($event, 'other')"
        />
      </div>
    </div>

    <div class="row s-corp-desc">
      <p class="col-md-12 text-left">
        <small>
          *An S corporation is an IRS tax election—it’s not an entity structure.
          The S corp election is available to most LLCs and corporations.
          We can help you file for an S corp election once you’ve formed your company.
        </small>
      </p>
    </div>

    <div class="row bottom-button-nav">
      <previous-button-component />
      <b-button
        class="continue-button btn"
        variant="primary"
        aria-label="continue button"
        :disabled="isContinueButtonDisabled"
        @click="handleContinueClick"
      >
        <span>
          Continue
        </span>
      </b-button>
    </div>
  </div>
  <div v-else class="center-spinner">
    <ct-centered-spinner />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { makeToastMixin } from '@/mixins/makeToastMixin'

export default {
  name: 'SelectEntityType',
  components: {
    CtCenteredSpinner:       () => import('@/components/shared/CtCenteredSpinner'),
    CtCardDeck:              () => import('@/components/shared/CtCardDeck'),
    CtCard:                  () => import('@/components/shared/CtCard'),
    LlcSvg:                  () => import('@images/illustrations/stageline/LLC_1.svg'),
    CorporationSvg:          () => import('@images/illustrations/stageline/Corporation_1.svg'),
    PreviousButtonComponent: () => import('./PreviousButtonComponent'),
  },

  mixins: [makeToastMixin],

  data() {
    return {
      selectedEntityTypeId: null,
      selectedEntityTypeDropdownId: null,
      selectedPaymentMethod: null,
      isSelectClicked: false,
      selectedEntityType: null,
    }
  },

  computed: {
    ...mapGetters('stagelineCreateCompany', [
      'jurisdiction',
      'entityType',
      'loaded',
    ]),
    ...mapGetters('entityTypes', [
      'entityTypes',
      'corporationEntityType',
      'llcEntityType',
      'nonLLCAndCorpEntityTypes',
    ]),
    isContinueButtonDisabled() {
      return this.selectedEntityType === null
    },
  },

  async mounted() {
    this.setLoaded(false)
    await this.loadEntityTypes()
    this.setSelectedEntityType()
    this.showLoading()
  },

  methods: {
    ...mapActions('stagelineCreateCompany', [
      'setCompanyEntityType',
      'setCurrentSlide',
      'setLoaded',
    ]),
    ...mapActions('entityTypes', ['getEntityTypes']),

    async loadEntityTypes() {
      if (!this.entityTypes?.length) {
        await this.getEntityTypes()
      }
    },
    setSelectedEntityType() {
      if (this.entityType !== null) {
        this.selectedEntityTypeId = this.entityType.id

        if (this.entityType.id !== this.llcEntityType.id &&
          this.entityType.id !== this.corporationEntityType.id) {
         this.selectedEntityTypeDropdownId = this.entityType.id
        }
      }
    },
    entitySelectChanged(entityTypeId, cardType) {
      // Set the selectedPaymentMethod data property to the clicked card type (teal border)
      this.selectedPaymentMethod = cardType
      // Store the selected entity type in the selectedEntityType data property
      this.selectedEntityType = cardType
      if (entityTypeId) {
        this.setEntityTypeAndNavigateToCompany(entityTypeId)
      }
    },
    setEntityTypeAndNavigateToCompany(entityTypeId) {
      const selectedEntityType = this.entityTypes.find(et => et.id == entityTypeId)

      if (selectedEntityType) {
        this.setCompanyEntityType(selectedEntityType)
      } else {
        this.errorToast('Error', 'An error has occurred when attempting to save entity type, please try again.')
      }
    },
    showLoading() {
      setTimeout(() => {  this.setLoaded(true) }, 500)
    },
    handleContinueClick() {
      if (!this.selectedEntityType) {
        return
      }
      this.setCurrentSlide('CompanyName')
    },
  },
}
</script>

<style lang="scss" scoped>
@mixin flex-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.card__left {
  font-weight: 900;
  font-size: 1.1em;
  .card__image {
    height: 3.625em !important;
  }
}

.entity-type-input {
  opacity: 0;
}

.other-entity-dropdown-row {
  max-width: 39.375em;
  margin: 0.625em auto;
}
.other-entity-dropdown {
  ::v-deep .multiselect__tags {
    border-radius: $stageline-primary-border-radius !important;
  }
}
.s-corp-desc {
  max-width: 39.375em;
  margin: 1.25em auto;
}
.bottom-button-nav {
  margin: 0 auto;
  max-width: 40.625em;
  justify-content: flex-end;
}

.selected-payment-method {
  border: 0.125em solid #2FB2AE;
}
.previous-button {
  color: #2FB2AE;
}
</style>
