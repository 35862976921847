var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        [
          _c("div", { staticClass: "col-md-12 mb-3" }, [
            _c("h1", [
              _vm._v(
                "\n      Choose an entity type in " +
                  _vm._s(_vm.jurisdiction.state_province_region) +
                  ".\n    "
              ),
            ]),
          ]),
          _c(
            "ct-card-deck",
            [
              _vm.llcEntityType
                ? _c(
                    "ct-card",
                    {
                      class: {
                        "selected-payment-method":
                          _vm.selectedPaymentMethod === "llc",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.entitySelectChanged(
                            _vm.llcEntityType.id,
                            "llc"
                          )
                        },
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedEntityTypeId,
                            expression: "selectedEntityTypeId",
                          },
                        ],
                        staticClass: "entity-type-input",
                        attrs: {
                          id: "selectEntityTypeLLC",
                          type: "radio",
                          name: "selectEntityType",
                        },
                        domProps: {
                          value: _vm.llcEntityType.id,
                          checked: _vm._q(
                            _vm.selectedEntityTypeId,
                            _vm.llcEntityType.id
                          ),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedEntityTypeId = _vm.llcEntityType.id
                          },
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "card__left" },
                        [
                          _c("llc-svg", {
                            staticClass: "card__image",
                            attrs: { alt: "shop with awning" },
                          }),
                          _vm._v("\n        LLC\n      "),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "card__text text-left ml-3",
                          attrs: { for: "selectEntityTypeLLC" },
                        },
                        [
                          _vm._v(
                            "\n        Limited liability companies are simple and affordable to own,\n        making them the preferred structure for new and small business\n        owners. Most people choose an LLC.\n      "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.corporationEntityType
                ? _c(
                    "ct-card",
                    {
                      class: {
                        "selected-payment-method":
                          _vm.selectedPaymentMethod === "corporation",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.entitySelectChanged(
                            _vm.corporationEntityType.id,
                            "corporation"
                          )
                        },
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedEntityTypeId,
                            expression: "selectedEntityTypeId",
                          },
                        ],
                        staticClass: "entity-type-input",
                        attrs: {
                          id: "selectEntityTypeCorp",
                          type: "radio",
                          name: "selectEntityType",
                        },
                        domProps: {
                          value: _vm.corporationEntityType.id,
                          checked: _vm._q(
                            _vm.selectedEntityTypeId,
                            _vm.corporationEntityType.id
                          ),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedEntityTypeId =
                              _vm.corporationEntityType.id
                          },
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "card__left" },
                        [
                          _c("corporation-svg", {
                            staticClass: "card__image",
                            attrs: { alt: "buildings" },
                          }),
                          _vm._v("\n        Corporation\n      "),
                        ],
                        1
                      ),
                      _c(
                        "label",
                        {
                          staticClass: "card__text text-left ml-3",
                          attrs: { for: "selectEntityTypeCorp" },
                        },
                        [
                          _vm._v(
                            "\n        Corporations are more complex and expensive, but make excellent vehicles for formal\n        ownership and management structures, and for raising large sums of capital.\n      "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "row other-entity-dropdown-row" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "col-md-6 mt-2" },
              [
                _c("b-form-select", {
                  staticClass: "w-100 other-entity-dropdown float-left",
                  attrs: { options: _vm.nonLLCAndCorpEntityTypes },
                  on: {
                    change: function ($event) {
                      return _vm.entitySelectChanged($event, "other")
                    },
                  },
                  model: {
                    value: _vm.selectedEntityTypeDropdownId,
                    callback: function ($$v) {
                      _vm.selectedEntityTypeDropdownId = $$v
                    },
                    expression: "selectedEntityTypeDropdownId",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._m(1),
          _c(
            "div",
            { staticClass: "row bottom-button-nav" },
            [
              _c("previous-button-component"),
              _c(
                "b-button",
                {
                  staticClass: "continue-button btn",
                  attrs: {
                    variant: "primary",
                    "aria-label": "continue button",
                    disabled: _vm.isContinueButtonDisabled,
                  },
                  on: { click: _vm.handleContinueClick },
                },
                [_c("span", [_vm._v("\n        Continue\n      ")])]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "center-spinner" },
        [_c("ct-centered-spinner")],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "col-md-6 mb-2 mb-md-0 vertical-align d-flex flex-column align-items-start",
      },
      [
        _c("h6", { staticClass: "mt-0 mb-2" }, [
          _vm._v("\n        Looking for something else?\n      "),
        ]),
        _c("p", { staticClass: "text-left" }, [
          _c("small", [
            _vm._v(
              "\n          Our business guide supports additional entity types\n          that you can add to your account instead.\n        "
            ),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row s-corp-desc" }, [
      _c("p", { staticClass: "col-md-12 text-left" }, [
        _c("small", [
          _vm._v(
            "\n        *An S corporation is an IRS tax election—it’s not an entity structure.\n        The S corp election is available to most LLCs and corporations.\n        We can help you file for an S corp election once you’ve formed your company.\n      "
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }